"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _vuex = require("vuex");
var _errorParse = require("@/utils/error-parse");
var _attendance = require("@/api/attendance");
var _user = require("@/api/user");
var _store = require("@/api/store");
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data() {
    return {
      list: null,
      listLoading: false,
      users: [],
      userOptions: [],
      stores: [],
      listQuery: {
        fromTime: null,
        toTime: null,
        storeId: null,
        page: 1,
        userId: null,
        pendingAdjustment: null,
        perPage: 10
      },
      total: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['name', 'user']),
    page: {
      get() {
        return this.listQuery.page;
      }
    },
    perPage: {
      get() {
        return this.listQuery.perPage;
      }
    },
    dateRange: {
      get() {
        return [this.listQuery.fromTime ? this.listQuery.fromTime : '', this.listQuery.toTime ? this.listQuery.toTime : ''];
      },
      set(range) {
        if (range != null) {
          this.listQuery.fromTime = range[0];
          this.listQuery.toTime = range[1];
        } else {
          this.listQuery.fromTime = null;
          this.listQuery.toTime = null;
        }
      }
    }
  },
  watch: {
    page() {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: this.listQuery.page
        }
      });
    },
    perPage() {
      this.$router.push({
        query: {
          ...this.$route.query,
          perPage: this.listQuery.perPage
        }
      });
    }
  },
  async created() {
    for (const key in this.listQuery) {
      if (this.$route.query[key]) {
        if (typeof this.listQuery[key] === 'number') {
          this.listQuery[key] = Number(this.$route.query[key]);
        } else if (key === 'fromTime' || key === 'toTime') {
          const date = new Date(this.$route.query[key]);
          // Check if the parsed date is valid
          if (isNaN(date)) {
            this.listQuery[key] = null;
          } else {
            const formattedDate = date.toISOString().split('T')[0];
            this.listQuery[key] = formattedDate; // Return the formatted date on success
          }
        } else {
          this.listQuery[key] = this.$route.query[key];
        }
      }
    }
    this.fetchData();
    this.fetchUsers();
    this.fetchStores();
  },
  methods: {
    fetchUsers() {
      (0, _user.getUsers)({
        limit: 999,
        active: 'true',
        roles: ['staff.parttime', 'staff']
      }).then(response => {
        this.users = response.data;
        this.userOptions = response.data;
      });
    },
    userFilter(val) {
      this.value = val;
      if (val) {
        this.userOptions = this.users.filter(item => {
          if (!!~item.name.indexOf(val) || !!~item.name.toUpperCase().indexOf(val.toUpperCase())) {
            return true;
          }
        });
      } else {
        this.userOptions = this.users;
      }
    },
    fetchStores() {
      (0, _store.getStores)({
        limit: 999
      }).then(response => {
        this.stores = response.data;
      });
    },
    query() {
      const param = {};
      for (const key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }
        if (key === 'fromTime' || key === 'toTime') {
          let current_datetime = this.listQuery[key];
          if (typeof current_datetime === 'string') {
            current_datetime = new Date(current_datetime);
          }
          param[key] = current_datetime.getFullYear() + '-' + (current_datetime.getMonth() + 1) + '-' + current_datetime.getDate();
        } else {
          param[key] = this.listQuery[key];
        }
      }
      this.$router.push({
        query: {
          ...this.listQuery,
          ...param
        }
      });
      this.fetchData();
    },
    fetchData(refreshing = false) {
      const param = {};
      for (const key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }
        if (this.listQuery[key] === 'yes') {
          param[key] = 1;
        } else if (this.listQuery[key] === 'no') {
          param[key] = 0;
        } else {
          param[key] = this.listQuery[key];
        }
      }
      if (refreshing) {
        this.listLoading = true;
        if (param.page !== 1 && param.page !== '1' || param.perPage !== 10 && param.perPage !== '10') {
          (0, _attendance.getAttendances)({
            page: 1,
            perPage: 10,
            hash: Math.floor(Math.random() * 100) + 1
          }).then(response => {
            if (response.status === 200) {
              return caches.open('data').then(cache => {
                return cache.put(new Request({
                  url: `${process.env.VUE_APP_BASE_API}/api/v1/attendances?page=1&perPage=10`,
                  method: 'GET'
                }), response);
              });
            }
            return Promise.resolve('cache cancelled');
          });
        }
        (0, _attendance.getAttendances)({
          ...param,
          hash: Math.floor(Math.random() * 100) + 1
        }).then(response => {
          if (response.status === 200) {
            this.list = response.data;
            this.total = response.meta.total;
            return caches.open('data').then(cache => {
              return cache.put(new Request({
                url: `${process.env.VUE_APP_BASE_API}/api/v1/attendances?page=${this.listQuery.page}&perPage=${this.listQuery.perPage}`,
                method: 'GET'
              }), response);
            });
          }
          return Promise.resolve('cache cancelled');
        }).finally(() => {
          this.listLoading = false;
        });
      }
      this.listLoading = true;
      return (0, _attendance.getAttendances)(param).then(response => {
        this.list = response.data;
        this.total = response.meta.total;
      }).catch(async err => {
        if (err.status === 429) {
          const adBlockerDetector = document.createElement('div');
          adBlockerDetector.id = 'ad';
          document.body.appendChild(adBlockerDetector);
          const adElement = document.getElementById('ad');
          if (this.$workbox && this.$workbox.active instanceof Promise) {
            const uniqueValue = window['Symbol'] ? Symbol('unique') : /unique/;
            Promise.race([this.$workbox.active, Promise.resolve(uniqueValue)]).then(value => {
              if (value === uniqueValue) {
                if (adElement === null) {
                  document.body.removeChild(adBlockerDetector);
                  // Ad blocker detected
                  _elementUi.MessageBox.alert('Please disable ad blocker, your browser blocking service worker to run', 'Error in Browser detected', {
                    confirmButtonText: 'Ok'
                  }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
                }
              }
            }, () => {
              if (adElement === null) {
                document.body.removeChild(adBlockerDetector);
                // Ad blocker detected
                _elementUi.MessageBox.alert('Please disable ad blocker, your browser blocking service worker to run', 'Error in Browser detected', {
                  confirmButtonText: 'Ok'
                }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
              }
            });
          } else {
            if (adElement === null) {
              document.body.removeChild(adBlockerDetector);
              // Ad blocker detected
              _elementUi.MessageBox.alert('Please disable ad blocker, your browser blocking service worker to run', 'Error in Browser detected', {
                confirmButtonText: 'Ok'
              }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
            }
          }
        }
      }).finally(() => {
        this.listLoading = false;
      });
    },
    promiseState(promise, callback) {
      // Symbols and RegExps are never content-equal
      const uniqueValue = window['Symbol'] ? Symbol('unique') : /unique/;
      function notifyPendingOrResolved(value) {
        if (value === uniqueValue) {
          return callback('pending');
        } else {
          return callback('fulfilled');
        }
      }
      function notifyRejected(reason) {
        return callback('rejected');
      }
      const race = [promise, Promise.resolve(uniqueValue)];
      Promise.race(race).then(notifyPendingOrResolved, notifyRejected);
    },
    deleteAttendance(id) {
      return _elementUi.MessageBox.confirm('Confirm to delete attendance?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _attendance.deleteAttendance)(id)).catch(err => err.status === 404 ? Promise.resolve('already deleted') : (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      })).then(() => {
        (0, _elementUi.Message)({
          message: 'Attendance deleted',
          type: 'success',
          duration: 5 * 1000
        });
        if (this.$workbox && this.$workbox.active instanceof Promise) {
          const uniqueValue = window['Symbol'] ? Symbol('unique') : /unique/;
          return Promise.race([this.$workbox.active, Promise.resolve(uniqueValue)]).then(value => {
            if (value !== uniqueValue) {
              return this.fetchData(true);
            } else {
              return this.fetchData();
            }
          }, () => {
            return this.fetchData();
          });
        } else {
          return this.fetchData();
        }
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },
    addAttendance() {
      this.$router.push(`/attendances/add`);
    },
    approveAdjustment(uuid) {
      return _elementUi.MessageBox.confirm('Confirm to approve attendance?', 'Confirm', {
        confirmButtonText: 'Approve',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _attendance.approveAdjustment)(uuid).then(() => {
        (0, _elementUi.Message)({
          message: 'Adjustment approved',
          type: 'success',
          duration: 5 * 1000
        });
        if (this.$workbox && this.$workbox.active instanceof Promise) {
          const uniqueValue = window['Symbol'] ? Symbol('unique') : /unique/;
          return Promise.race([this.$workbox.active, Promise.resolve(uniqueValue)]).then(value => {
            if (value !== uniqueValue) {
              return this.fetchData(true);
            } else {
              return this.fetchData();
            }
          }, () => {
            return this.fetchData();
          });
        } else {
          return this.fetchData();
        }
      }).catch(err => (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      })));
    },
    rejectAdjustment(uuid) {
      return _elementUi.MessageBox.confirm('Confirm to reject attendance?', 'Confirm', {
        confirmButtonText: 'Reject',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _attendance.rejectAdjustment)(uuid)(() => {
        (0, _elementUi.Message)({
          message: 'Adjustment rejected',
          type: 'success',
          duration: 5 * 1000
        });
        if (this.$workbox && this.$workbox.active instanceof Promise) {
          const uniqueValue = window['Symbol'] ? Symbol('unique') : /unique/;
          return Promise.race([this.$workbox.active, Promise.resolve(uniqueValue)]).then(value => {
            if (value !== uniqueValue) {
              return this.fetchData(true);
            } else {
              return this.fetchData();
            }
          }, () => {
            return this.fetchData();
          });
        } else {
          return this.fetchData();
        }
      }).catch(err => (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      })));
    },
    applyAdjustment(attendance, target) {
      return _elementUi.MessageBox.prompt('Please state the reason for requesting adjustment to ' + target + ' ?', 'Request Adjustment', {
        confirmButtonText: 'Request',
        cancelButtonText: 'Cancel'
      }).then(value => (0, _attendance.requestAdjustment)({
        attendanceId: attendance.uuid,
        adjustedTime: target,
        remark: value.value
      }).then(() => {
        (0, _elementUi.Message)({
          message: 'Adjustment Requested',
          type: 'success',
          duration: 5 * 1000
        });
        if (this.$workbox && this.$workbox.active instanceof Promise) {
          const uniqueValue = window['Symbol'] ? Symbol('unique') : /unique/;
          return Promise.race([this.$workbox.active, Promise.resolve(uniqueValue)]).then(value => {
            if (value !== uniqueValue) {
              return this.fetchData(true);
            } else {
              return this.fetchData();
            }
          }, () => {
            return this.fetchData();
          });
        } else {
          return this.fetchData();
        }
      })).catch(err => {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
          type: 'error',
          duration: 5 * 1000
        });
        // console.log(err)
      });
    }
  }
};
exports.default = _default;