"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _cashExpense = require("@/api/cashExpense");
var _store = require("@/api/store");
var _i18n = _interopRequireDefault(require("@/i18n"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Pagination from '@/components/Pagination'
// import { MessageBox, Message } from 'element-ui'
var _default = {
  /* filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  }, */
  data() {
    return {
      categories: [{
        id: '',
        name: _i18n.default.t('CashExpense.All')
      }, {
        id: 'Stationary',
        name: _i18n.default.t('CashExpense.Stationary')
      }, {
        id: 'Food',
        name: _i18n.default.t('CashExpense.Food')
      }, {
        id: 'Miscellaneous',
        name: _i18n.default.t('CashExpense.Miscellaneous')
      }, {
        id: 'Laundry',
        name: _i18n.default.t('CashExpense.Laundry')
      },
      // {id : "InkBox", name : i18n.t('CashExpense.InkBox')},
      {
        id: 'Logestic',
        name: _i18n.default.t('CashExpense.Logestic')
      }, {
        id: 'Cleaning',
        name: _i18n.default.t('CashExpense.Cleaning')
      }, {
        id: 'FoodIngredients',
        name: _i18n.default.t('CashExpense.FoodIngredients')
      }, {
        id: 'Bar',
        name: _i18n.default.t('CashExpense.Bar')
      }],
      stores: [{
        uuid: '',
        name: 'All Stores'
      }],
      list: null,
      listLoading: false,
      listQuery: {
        category: '',
        store_id: '',
        date_range: [],
        page: 1,
        limit: 10,
        listAll: true
      },
      total: 0
    };
  },
  async created() {
    (0, _store.getStores)().then(storeResponse => {
      const storeData = storeResponse.data;
      storeData.splice(0, 0, {
        uuid: '',
        name: 'All Stores'
      });
      this.stores = storeData;
    });
    const start = new Date();
    start.setDate(start.getDate() - 7);
    let dd = String(start.getDate()).padStart(2, '0');
    let mm = String(start.getMonth() + 1).padStart(2, '0'); // January is 0!
    let yyyy = start.getFullYear();
    const format1 = yyyy + '-' + mm + '-' + dd;
    const next = new Date();
    // next.setMonth(next.getMonth()+1);
    // next = new Date(next.getFullYear()+"-"+(String(next.getMonth() + 1).padStart(2, '0'))+"-01");
    // next.setDate(next.getDate()-1);

    dd = String(next.getDate()).padStart(2, '0');
    mm = String(next.getMonth() + 1).padStart(2, '0'); // January is 0!
    yyyy = next.getFullYear();
    const format2 = yyyy + '-' + mm + '-' + dd;
    this.listQuery.date_range = [format1, format2];
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.listLoading = true;
      (0, _cashExpense.getReport)(this.listQuery).then(response => {
        this.list = response;
      }).finally(() => {
        this.listLoading = false;
      });
    }
  }
};
exports.default = _default;