"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _errorParse = require("@/utils/error-parse");
var _elementUi = require("element-ui");
var _user = require("@/api/user");
var _duty = require("@/api/duty");
var _attendance = require("@/api/attendance");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// getStoresimport { getStores } from '@/api/store'
// import { getShift } from '@/api/shift'
var _default = {
  data() {
    return {
      loading: false,
      attendance: {
        id: null,
        action: 'clock_in',
        userId: null,
        dutyId: null,
        mockTime: null
      },
      users: [],
      userOptions: [],
      /* stores: [], */
      duties: [],
      buttonText: 'Add',
      rules: {
        userId: {
          required: true,
          message: 'User is required'
        },
        action: {
          required: true,
          message: 'Action is required'
        },
        dutyId: {
          required: true,
          message: 'Duty is required'
        },
        mockTime: {
          required: true,
          message: 'Time is required'
        }
      },
      isEdit: false
    };
  },
  computed: {
    device() {
      return this.$store.state.app.device;
    }
  },
  async created() {
    // this.fetchStores()
    this.fetchUsers();
    if (this.$route.params.id) {
      this.buttonText = 'Edit';
      this.isEdit = true;
      // this.fetchData()
    } else {
      this.buttonText = 'Add';
    }
  },
  methods: {
    async fetchUsers() {
      (0, _user.getUsers)({
        limit: 999,
        active: 'true',
        roles: ['staff.parttime', 'staff']
      }).then(response => {
        this.users = Object.freeze(response.data.map(user => ({
          id: user.uuid,
          uuid: user.uuid,
          name: user.name
        })));
        this.userOptions = this.users;
      });
    },
    userFilter(val) {
      // this.value = val
      if (val) {
        this.userOptions = this.users.filter(item => {
          if (!!~item.name.indexOf(val) || !!~item.name.toUpperCase().indexOf(val.toUpperCase())) {
            return true;
          }
        });
      } else {
        this.userOptions = this.users;
      }
    },
    /* fetchData() {
      this.loading = true
      getShift(this.$route.params.id).then(response => {
        response.data.fromTime = '' + response.data.from_hour + ':' + (response.data.from_min > 9 ? '' : '0') + response.data.from_min
        response.data.toTime = '' + response.data.to_hour + ':' + (response.data.to_min > 9 ? '' : '0') + response.data.to_min
        this.shift = response.data
        this.shift.roleId = response.data.role.id
      }).catch(error => console.error(error)).finally(() => {
        this.loading = false
      })
    }, */
    /* async fetchStores() {
      getStores({ limit: 999 }).then(response => {
        this.stores = response.data
      })
    }, */
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        } else {
          this.loading = true;
          (0, _attendance.createAttendance)(this.attendance).then(() => {
            (0, _elementUi.Message)({
              message: 'Attendance created.',
              type: 'success',
              duration: 5 * 1000
            });
            if (this.$workbox && this.$workbox.active instanceof Promise) {
              const uniqueValue = window['Symbol'] ? Symbol('unique') : /unique/;
              return Promise.race([this.$workbox.active, Promise.resolve(uniqueValue)]).then(value => {
                if (value !== uniqueValue) {
                  return (0, _attendance.getAttendances)({
                    page: 1,
                    perPage: 10,
                    hash: Math.floor(Math.random() * 100) + 1
                  }).then(response => {
                    if (response.status === 200) {
                      return caches.open('data').then(cache => {
                        return cache.put(new Request({
                          url: `${process.env.VUE_APP_BASE_API}/api/v1/attendances?page=1&perPage=10`,
                          method: 'GET'
                        }), response);
                      });
                    }
                    return Promise.resolve('cache cancelled');
                  }).finally(() => {
                    this.$router.push(`/attendances`);
                  });
                } else {
                  this.$router.push(`/attendances`);
                }
              }, () => {
                this.$router.push(`/attendances`);
              });
            } else {
              this.$router.push(`/attendances`);
            }
          }).catch(err => {
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: (0, _errorParse.parseError)(err) || 'Create failed, please retry.',
              type: 'error',
              duration: 5 * 1000
            });
            this.loading = false;
          });
        }
      });
    },
    onCancel() {
      this.$router.push(`/attendances`);
    },
    searchDuty() {
      this.$refs.form.validate((valid, errors) => {
        const errs = {
          ...errors
        };
        delete errs.dutyId;
        if (!valid && Object.keys(errs).length) {
          return false;
        } else {
          if (!this.attendance.mockTime) {
            return null;
          }
          const formatted_date = this.attendance.mockTime.getFullYear() + '-' + (this.attendance.mockTime.getMonth() + 1) + '-' + this.attendance.mockTime.getDate() + ' ' + this.attendance.mockTime.getHours().toString().padStart(2, '0') + ':' + this.attendance.mockTime.getMinutes().toString().padStart(2, '0') + ':' + this.attendance.mockTime.getSeconds().toString().padStart(2, '0');
          (0, _duty.possibleDuties)({
            mockTime: formatted_date,
            action: this.attendance.action,
            userId: this.attendance.userId
          }).then(response => {
            this.duties = response.data;
            if (this.duties.length === 0) {
              this.attendance.dutyId = null;
            } else if (this.duties.length) {
              this.attendance.dutyId = this.duties[0].uuid;
            }
          });
        }
      });
    }
  }
};
exports.default = _default;